import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import Experts from "../components/Experts"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
import Topographer from "../assets/icons/Topographer.svg";
import TopographerImage from "../assets/images/topographer.webp";
const SwornTopographer = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Sworn Topographer" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "Sworn Topographer"
                : "طوبوغرافي محلف"
            }
            subTitle={
              language == false
                ? "Crafting Clarity from Complexity: The Topographer's Artful Science"
                : "صياغة الوضوح من التعقيد: علم الطبوغرافيين الماهرين"
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

      
              <Experts
                inverted={false}
                Icon={Topographer}
                image={TopographerImage}
                title={
                  language == false
                    ? "Sworn Topographer"
                    : "طوبوغرافي محلف"
                }
                description={
                  language == false
                    ? "At the heart of every successful construction project, environmental management plan, and property transaction lies the unparalleled precision of a sworn topographer. Our certified experts are the keystones in mapping the world with absolute accuracy, blending geographical insight with legal expertise to ensure your land's boundaries are defined with unerring precision. Whether you're navigating the complexities of property disputes, planning the next big infrastructure development, or laying the groundwork for sustainable land use, our sworn topographers are equipped with state-of-the-art technology to bring clarity and resolution to your projects."
                    : "في قلب كل مشروع بناء ناجح، وخطة الإدارة البيئية، والمعاملات العقارية، تكمن الدقة التي لا مثيل لها لطوبوغرافي محلف. خبراؤنا المعتمدون هم حجر الأساس في رسم خرائط العالم بدقة مطلقة، حيث يمزجون الرؤية الجغرافية مع الخبرة القانونية لضمان تحديد حدود أرضك بدقة لا تشوبها شائبة. سواء كنت تتغلب على تعقيدات النزاعات العقارية، أو تخطط لتطوير البنية التحتية الكبيرة التالية، أو تضع الأساس للاستخدام المستدام للأراضي، فإن الطوبوغرافيين المعتمدين لدينا مجهزون بأحدث التقنيات لتحقيق الوضوح والحل لمشاريعك."
                }
                label={
                  language == false
                  ? "Call Our Topographer"
                  : "اتصل بأخصائي الطوبوغرافي لدينا"
                }
                link={"tel:+9613948739"}
                arabic={language}
              />
          <br/><br/><br/>
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default SwornTopographer
